import React, { useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import {
	DataObserveKey,
	InlineText,
	SrOnly,
	VisualComponent,
	useFormattingLanguage,
	cn,
} from "@siteimprove/fancylib";
import { combinedRefs, stopEvent } from "../../../utils/shorthands";
import { useElementSize } from "../../../utils/hooks";
import { toFormattedNumberString } from "../../text/formatted-number/formatted-number";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import { useLabTranslations } from "../../../translations/translations";
import { useDesignToken } from "../../context/theme/theme";
import { arrowDirectionStyling, mergeTransformStyles } from "../../../utils/styling-utils";
import * as scss from "./spark-line.scss";

export type LineStyle = "solid" | "dashed" | "dotted";

export type SeriesOptions = {
	/** Serie of numbers (y-coordinate) */
	data: number[];
	/** Line color used on svg strike */
	lineColor?: string;
	/** Line style used on svg strike */
	lineStyle?: LineStyle;
	/** Line width used on svg strike */
	lineWidth?: number;
	/** CSS class */
	className?: string;
	/** Prefix used to display data points */
	dataPrefix?: string;
	/** Postfix used to display data points */
	dataPostfix?: string;
	/** Text formatter function used to display data points */
	dataFormatter?: (value: number, idx: number) => string;
};

export type SparkLineProps = {
	/** Data series */
	series: SeriesOptions | SeriesOptions[];
	/** Size of the spark line (defaults to `"large"`) */
	size?: "small" | "large";
	/** Optional min Y value */
	minY?: number;
	/** Optional max Y value */
	maxY?: number;
	/** Radius used on svg strike points */
	pointSize?: number;
	/** Label of the spark line */
	"aria-label"?: string;
	/** ID of an an element that labels this spark line */
	"aria-labelledby"?: string;
	/** ID of an an element that describes the spark line content */
	"aria-describedby"?: string;
} & DataObserveKey &
	VisualComponent;

export function SparkLine(props: SparkLineProps): JSX.Element {
	const { series, className, style, minY, maxY, size = "large", pointSize = 3 } = props;

	// some dimension related constants
	const pointHighlightBorderSize = 2;
	const pointShadowRadius: number = pointSize + 2;
	const padding: number = pointShadowRadius + pointHighlightBorderSize;

	// sparkline container is resizing aware
	const containerRef = useRef<HTMLDivElement>(null);
	const [width, height] = useElementSize(containerRef);

	const [isHovering, setIsHovering] = useState(false);

	// tooltip properties
	const [tooltipVisibility, setTooltipVisibility] = useState<VisibilityState>("hidden");
	const [tooltipContent, setTooltipContent] = useState<string>("");
	const [tooltipAnchor, setTooltipAnchor] = useState<SVGGElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
	const { styles, attributes } = usePopper(tooltipAnchor, popperElement, {
		placement: "bottom",
		modifiers: [
			{ name: "arrow", options: { element: arrowElement } },
			{ name: "offset", options: { offset: [0, 10] } },
		],
	});

	// ensures series is an Array
	const seriesList: SeriesOptions[] = Array.isArray(series) ? series : [series];

	// creates sparkline scale to be used in all sub components
	const scale: ScaleFunction = useMemo<ScaleFunction>(
		() =>
			getScaleFunction({
				maxYValue: maxY,
				minYValue: minY,
				padding,
				seriesList,
				viewBoxHeight: height,
				viewBoxWidth: width,
			}),
		[seriesList, pointSize, maxY, minY, height, width]
	);

	// a11y properties
	const i18n = useLabTranslations();
	const defaultAriaLabel = useMemo<string>(() => {
		const numberOfPoints = Math.max(
			...seriesList.map((series: SeriesOptions) => series.data.length)
		);
		const ariaLabel = i18n.sparkLineAriaLabel.replace("[NUMBER_OF_POINTS]", `${numberOfPoints}`);
		return ariaLabel;
	}, [seriesList]);

	return (
		<div
			aria-describedby={props["aria-describedby"]}
			aria-label={props["aria-label"] || defaultAriaLabel}
			aria-labelledby={props["aria-labelledby"]}
			className={cn(scss.sparkLine, scss[size], className)}
			data-component="sparkline"
			data-observe-key={props["data-observe-key"]}
			ref={containerRef}
			role="group"
			style={style}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<svg
				focusable={false}
				height={height}
				preserveAspectRatio="xMidYMid meet"
				role="presentation"
				viewBox={`0 0 ${width} ${height}`}
				width={width || "100%"}
			>
				{seriesList.map(
					(series: SeriesOptions, seriesIndex: number) =>
						series.data.length > 1 && (
							<SeriesLinePath
								key={seriesIndex}
								pointSize={pointSize}
								scale={scale}
								series={series}
								seriesIndex={seriesIndex}
							/>
						)
				)}
				{isHovering && (
					<SeriesDataPointsList
						pointHighlightBorderSize={pointHighlightBorderSize}
						pointShadowRadius={pointShadowRadius}
						pointSize={pointSize}
						scale={scale}
						seriesList={seriesList}
						setTooltipContent={setTooltipContent}
						setTooltipVisibility={setTooltipVisibility}
						setTooltipAnchor={setTooltipAnchor}
						tooltipContent={tooltipContent}
					/>
				)}
			</svg>
			{seriesList.length === 0 && <SrOnly>Empty sparkline</SrOnly>}
			<div
				aria-hidden="true"
				className={cn(scss.sparkLineTooltip)}
				ref={setPopperElement}
				style={{ ...styles.popper, visibility: tooltipVisibility }}
				{...attributes.popper}
			>
				<InlineText
					className={scss.sparkLineTooltipText}
					tone="neutralLight"
					lineHeight="multi-line"
				>
					{tooltipContent}
				</InlineText>
				<div ref={setArrowElement} style={styles.arrow} />
				<div
					ref={setArrowElement}
					className={scss.sparkLineTooltipArrow}
					style={mergeTransformStyles(
						styles.arrow,
						arrowDirectionStyling(popperElement?.getAttribute("data-popper-placement") || "bottom")
					)}
				/>
			</div>
		</div>
	);
}

const SeriesLinePath = (props: SeriesLinePathProps): JSX.Element => {
	const { series, seriesIndex, scale } = props;
	const { className, data, lineWidth = 2 } = series;
	const designTokens = useDesignToken();
	return (
		<path
			className={cn(className)}
			d={getPathD(data, scale)}
			stroke={getSeriesColor(series, seriesIndex, designTokens)}
			strokeDasharray={lineStyleToDashedArray(getSeriesLineStyle(series, seriesIndex))}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={`${lineWidth}px`}
			vectorEffect="non-scaling-stroke"
		/>
	);
};

const SeriesDataPointsList = (props: SeriesDataPointListProps): JSX.Element => {
	const { seriesList, scale, tooltipContent, setTooltipVisibility } = props;
	const maxX: number = Math.max(0, ...seriesList.map((series) => series.data.length));
	const indexes: number[] = [...Array(maxX).keys()];
	const dx: number = getXStepSize(indexes, scale);
	const pointsRefs: SVGGElement[] = [];

	const keyboardNavigationHandler = (event: React.KeyboardEvent<SVGGElement>) => {
		// Should do nothing if the default action has been cancelled
		if (event.defaultPrevented) {
			return;
		}

		// ESC key hiddes any visible tooltip
		if (event.keyCode === KeyCode.Escape) {
			stopEvent(event);
			setTooltipVisibility("hidden");
			return;
		}

		// Enter key toggles the tooltip back, if any
		if (event.keyCode === KeyCode.Enter && tooltipContent) {
			stopEvent(event);
			setTooltipVisibility("visible");
			return;
		}

		// handler for navgation keys, such as Arrows, Home and End
		const currentIdx = pointsRefs.indexOf(event.target as SVGGElement);
		const idx = keyCodeToIndex(event.keyCode, currentIdx, pointsRefs.length - 1);
		if (idx !== null) {
			stopEvent(event);
			pointsRefs[idx].focus();
			return;
		}
	};

	// interactive <g> elements should have display block to avoid
	// NVDA screen reader from speaking all data points at once
	const gStyle = { display: "block" };

	return (
		<g onKeyDown={keyboardNavigationHandler} style={gStyle}>
			{indexes.map((index) => (
				<SeriesDataPoint
					key={index}
					pointIndex={index}
					dx={dx}
					ref={(ref) => ref && (pointsRefs[index] = ref)}
					{...props}
				/>
			))}
		</g>
	);
};

const SeriesDataPoint = React.forwardRef<SVGGElement | null, SeriesDataPointProps>(
	(props: SeriesDataPointProps, ref): JSX.Element => {
		const {
			dx,
			pointIndex,
			pointShadowRadius,
			pointHighlightBorderSize,
			pointSize,
			scale,
			seriesList,
			setTooltipAnchor,
			setTooltipContent,
			setTooltipVisibility,
		} = props;

		const verticalRuleWidth = 2;
		const scaledX: number = scale(pointIndex, 0)[0];
		const locale = useFormattingLanguage();
		const designTokens = useDesignToken();

		const [visibility, setVisibility] = useState<VisibilityState>("hidden");
		const innerRef = useRef<SVGGElement | null>(null);

		// The focus of the first SparkLine point is reached by the <Tab> key,
		// however the focus of the other points is reached through the Arrows,
		// Home and END navigation keys.
		const tabIndex = pointIndex === 0 ? 0 : -1;
		const tooltipContent = buildTooltipContent(pointIndex, seriesList, locale);

		// interactive <g> elements should have display block to avoid
		// NVDA screen reader from speaking all data points at once
		const gStyle = { display: "block" };

		const displayDataPoint = () => {
			if (!ref) return;
			setTooltipAnchor(innerRef.current);
			setTooltipContent(tooltipContent);
			setTooltipVisibility("visible");
			setVisibility("visible");
		};

		const hideDataPoint = () => {
			setTooltipVisibility("hidden");
			setVisibility("hidden");
			setTooltipContent("");
		};

		return (
			<>
				<g
					aria-label={tooltipContent}
					className={cn(scss.sparkLinePoint)}
					onBlur={hideDataPoint}
					onFocus={displayDataPoint}
					onMouseEnter={displayDataPoint}
					onMouseLeave={hideDataPoint}
					ref={combinedRefs<SVGGElement>(innerRef, ref)}
					role="img"
					style={gStyle}
					tabIndex={tabIndex}
				>
					<rect
						className={cn(scss.sparkLinePointUnderlay)}
						height="100%"
						width={dx}
						x={scaledX - dx / 2}
						y={0}
					/>
					<g visibility={visibility}>
						<rect
							className={cn(scss.sparkLinePointVerticalRule)}
							height="100%"
							width={verticalRuleWidth}
							x={scaledX - verticalRuleWidth / 2}
							y={0}
						/>
						{seriesList.map((series: SeriesOptions, seriesIndex: number) => {
							// edge cases
							if (series.data.length <= 1 || pointIndex >= series.data.length) return null;

							const { className } = series;
							const scaledY: number = scale(pointIndex, series.data[pointIndex])[1];
							return (
								<g key={seriesIndex} className={cn(className)}>
									<circle
										className={cn(scss.sparkLinePointShadow)}
										cx={scaledX}
										cy={scaledY}
										r={pointShadowRadius}
									/>
									<circle
										cx={scaledX}
										cy={scaledY}
										r={pointSize}
										fill={getSeriesColor(series, seriesIndex, designTokens)}
									/>
									<rect
										className={cn(scss.sparkLinePointHighlightBorder)}
										data-z-index={99}
										height={pointShadowRadius * 2}
										rx={2}
										ry={2}
										strokeWidth={pointHighlightBorderSize}
										width={pointShadowRadius * 2}
										x={scaledX - pointShadowRadius}
										y={scaledY - pointShadowRadius}
									></rect>
								</g>
							);
						})}
					</g>
				</g>
			</>
		);
	}
);

// build the prop `d` of a SVG <path> element from a serie of numbers
function getPathD(data: number[], scale: ScaleFunction): string {
	const scaledData: number[][] = data.map((y, x) => scale(x, y));
	const l: string[] = scaledData.map(([x, y]) => x + "," + y);
	return `M ${l.join(" L ")}`;
}

// returns a scale function that maps a [x,y] coordinate to the sparkline domain
function getScaleFunction(options: ScaleFunctionOptions): ScaleFunction {
	const { seriesList, viewBoxWidth, viewBoxHeight, padding, minYValue, maxYValue } = options;

	// get min and max Y values from all series
	const allData: number[] = seriesList.reduce(
		(flatData: number[], series: SeriesOptions) => [...flatData, ...series.data],
		[]
	);
	const maxY: number = maxYValue === undefined ? Math.max(...allData) : maxYValue;
	const minY: number = minYValue === undefined ? Math.min(0, ...allData) : minYValue;

	// gets the largest series length
	const maxX = Math.max(...seriesList.map((series: SeriesOptions) => series.data.length)) - 1;
	const minX = 0;

	return (x, y) => {
		const scaledX = mapRanges(x, [minX, maxX], [padding, viewBoxWidth - padding]);
		const scaledY = mapRanges(y, [minY, maxY], [padding, viewBoxHeight - padding]);
		// as y-axis origin is in the top-left corner, we need to translate it to the bottom-left corner
		const translatedY = viewBoxHeight - scaledY;
		return [scaledX, translatedY];
	};
}

// scaling between two number ranges
function mapRanges(value: number, r1: [number, number], r2: [number, number]): number {
	const epsilon = 10e-12; // safety margin to avoid division by zero
	return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0] + epsilon) + r2[0];
}

function formatDataPoint(
	value: number,
	idx: number,
	series: SeriesOptions,
	locale: string
): string {
	const { dataFormatter, dataPrefix = "", dataPostfix = "" } = series;
	if (dataFormatter) return dataFormatter(value, idx);

	const formattedValue = toFormattedNumberString({
		number: value,
		format: "number",
		alwaysShowDigits: false,
		digits: 2,
		locale: locale,
	});

	return `${dataPrefix}${formattedValue}${dataPostfix}`;
}

function buildTooltipContent(
	pointIndex: number,
	seriesList: SeriesOptions[],
	locale: string
): string {
	const content: string[] = [];
	seriesList.forEach((series: SeriesOptions) => {
		if (series.data[pointIndex] !== undefined) {
			content.push(formatDataPoint(series.data[pointIndex], pointIndex, series, locale));
		}
	});
	return content.join("\n");
}

function getXStepSize(xAxis: number[], scale: ScaleFunction): number {
	if (xAxis.length <= 1) return 0;
	const x1 = scale(xAxis[0], 0)[0];
	const x2 = scale(xAxis[1], 0)[0];
	const dx = Math.abs(x2 - x1);
	return dx;
}

function getSeriesColor(
	series: SeriesOptions,
	seriesIndex: number,
	designTokens: ReturnType<typeof useDesignToken>
): string {
	const defaultColors: string[] = [
		designTokens.ColorChart1,
		designTokens.ColorChart2,
		designTokens.ColorChart3,
		designTokens.ColorChart4,
		designTokens.ColorChart5,
		designTokens.ColorChart6,
		designTokens.ColorChart7,
		designTokens.ColorChart8,
	];
	const { lineColor } = series;
	return lineColor ? lineColor : defaultColors[seriesIndex % defaultColors.length];
}

const defaultLineStyles: LineStyle[] = ["solid", "dashed", "dotted"];
function getSeriesLineStyle(series: SeriesOptions, seriesIndex: number): LineStyle {
	const { lineStyle } = series;
	return lineStyle ? lineStyle : defaultLineStyles[seriesIndex % defaultLineStyles.length];
}

// get the strike-dashedarray value of a given line style
function lineStyleToDashedArray(lineStyle: LineStyle): string | undefined {
	const dashedArrayByLineStyle = {
		dashed: "5,3",
		dotted: "1,3",
		solid: undefined,
	};
	return dashedArrayByLineStyle[lineStyle];
}

// keyboard navigation keys
function keyCodeToIndex(keyCode: number, current: number, limit: number): number | null {
	switch (keyCode) {
		case KeyCode.ArrowLeft:
			return Math.max(current - 1, 0);
		case KeyCode.ArrowRight:
			return Math.min(current + 1, limit);
		case KeyCode.End:
			return limit;
		case KeyCode.Home:
			return 0;
	}
	return null;
}

type ScaleFunctionOptions = {
	maxYValue?: number;
	minYValue?: number;
	padding: number;
	seriesList: SeriesOptions[];
	viewBoxHeight: number;
	viewBoxWidth: number;
};

type ScaleFunction = (x: number, y: number) => [number, number];

type SeriesLinePathProps = {
	pointSize: number;
	scale: ScaleFunction;
	series: SeriesOptions;
	seriesIndex: number;
};

type VisibilityState = "hidden" | "visible";

type TooltipModifier = {
	readonly tooltipContent: string;
	setTooltipContent: React.Dispatch<React.SetStateAction<string>>;
	setTooltipVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>;
	setTooltipAnchor: React.Dispatch<React.SetStateAction<SVGGElement | null>>;
};

type SeriesDataPointListProps = {
	pointHighlightBorderSize: number;
	pointShadowRadius: number;
	pointSize: number;
	scale: ScaleFunction;
	seriesList: SeriesOptions[];
} & TooltipModifier;

type SeriesDataPointProps = {
	dx: number;
	pointIndex: number;
} & SeriesDataPointListProps;
