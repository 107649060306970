import React, { useLayoutEffect, useState } from "react";
import { Button, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import { ColorGrayLighter, ColorGreen } from "@siteimprove/fancylib/design-tokens/variables";
import {
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
	Knobs,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { toFormattedNumberString } from "../../text/formatted-number/formatted-number";
import { ListTable } from "../../tables-and-lists/list-table/list-table";
import { SeriesOptions, SparkLine } from "./spark-line";

export const Meta: DocPageMeta = {
	category: "Data Visualization",
	title: "Spark Line",
	notepad: "https://hackmd.io/-EDleSI3TTSICuQ9zDEpmA",
};
export default (): JSX.Element => (
	<>
		<HeaderSection
			title="SparkLine"
			subTitle="A compact line graph without axes, designed to visually represent numerical series trends within a limited space."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={SparkLine} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The <Code>SparkLine</Code> component features a few properties for adjusting its
					appearance and functionality. The following sections showcase these properties and explain
					when to use them.
				</Paragraph>
				<Example fn={BasicUsage} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Styling</Header.H3>
				<Header.H4>Line Color</Header.H4>
				<Paragraph>
					The <Code>lineColor</Code> property lets you specify the fill color for the line. If no
					color is provided, the component will use a default color palette.
				</Paragraph>
				<Example fn={LineColorExample} />
				<Header.H4>Line Style</Header.H4>
				<Paragraph>
					The <Code>lineStyle</Code> property allows you to choose one of three available line
					styles: <Code>solid</Code> (default), <Code>dashed</Code> (default), and{" "}
					<Code>dotted</Code>. For accessible, non-color-dependent components, choose line styles
					that allow series to be distinguished regardless of their color, especially when working
					with multiple series.
				</Paragraph>
				<Example fn={LineStyleExample} />
				<Header.H4>Size (TODO: feature to be confirmed)</Header.H4>
				<Paragraph>
					The <Code>size</Code> property lets you specify a height for the SparkLine:{" "}
					<Code>small</Code>, or <Code>large</Code> (default). Choose a size that's in proportion to
					the size of the container in which the SparkLine is used.
				</Paragraph>
				<Example fn={SizeExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Data Formatting</Header.H3>
				<Paragraph>
					The data-points are displayed through a tooltip when hovering over the SparkLine with the
					mouse or pointer. The textual formatting of datapoints can be controlled using the
					following properties:
				</Paragraph>
				<Header.H4>Using prefix and/or postfix</Header.H4>
				Use <Code>dataPrefix</Code> and/or <Code>dataPostfix</Code> to format the text by including
				characters before and after the datapoint value, respectively.
				<Example fn={PreAndPostFixDataFormatterExample} />
				<Header.H4>Using a custom formatting function</Header.H4>
				Use <Code>dataFormatter</Code> to have more control over the textual formatting of
				datapoints. In this way, it is also possible to format the numeric value for formats such as
				currency.
				<Example fn={CustomDataFormatterExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Ranges</Header.H3>
				<Paragraph>
					By default, the minimum and maximum value occupy the height of the sparkline. Optionally,{" "}
					<Code>minY</Code> and <Code>maxY</Code> values can be set.
				</Paragraph>
				<Example fn={RangesExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Multiple Series</Header.H3>
				<Paragraph>
					Display up to 3 series simultaneously on a single SparkLine. Series can have varying
					sizes.
				</Paragraph>
				<Example fn={MultipleSeriesExample} />
				<Paragraph>The series can also have different sizes.</Paragraph>
				<Example fn={MultipleSeriesSizesExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H3>Performance</Header.H3>
				<Paragraph>
					The following example lets you experience how SparkLines perform when rendering a large
					number of them. It shows a <Code>ListTable</Code> with 200 rows, where each row has a
					SparkLine with 3 series and each series has 30 points. Please click the button below to
					load this list.
				</Paragraph>
				<Example fn={PerformanceExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Knobs component={SparkLine} initialProps={{ series: { data: SERIE_DATA } }} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>SparkLine</Code> when
					</Paragraph>
					<Ul
						items={[
							"You need to quickly convey trends or patterns within numerical data.",
							"Space is limited and a full-fledged chart isn't feasible.",
							"You want to embed visual data representations within tables or lists.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>SparkLine</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Table cells</b>: To illustrate trends within tabular data.
							</>,
							<>
								<b>List items</b>: To quickly visualize the progress or status associated with each
								item.
							</>,
							<>
								<b>Dashboard widgets</b>: To provide compact summaries of key metrics.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>SparkLine</Code> to existing components for visual
								consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Precise numerical values are critical for the user.",
							"The data set is too small (fewer than 3-4 points) or too complex for a clear representation.",
							"Comparing multiple data sets with widely different scales.",
							"Users need to interact with specific data points through annotations or tooltips.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul items={["Ensure line styles provide differentiation beyond color."]} />
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>For data description,</Paragraph>
					<Ul
						items={[
							<>Use verbs like "increase," "decrease," "fluctuate," "stabilize," or "peak."</>,
							<>
								Quantify when appropriate: Include specific numbers or percentages to add precision.
							</>,
							<>
								Avoid vague terms: Don't rely on words like "good," "bad," or "better" without
								providing context or specific data points.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<SparkLine
		series={{
			data: [
				17, 93, 87, 35, 89, 28, 33, 91, 21, 78, 81, 48, 44, 31, 33, 35, 87, 87, 18, 10, 96, 75, 35,
				83, 16, 16,
			],
		}}
	/>
);

const LineColorExample = () => (
	<SparkLine
		series={{
			data: [43, 9, 92, 6, 35, 83, 16, 16.408],
			lineColor: ColorGreen,
		}}
	/>
);

const LineStyleExample = () => (
	<>
		<SparkLine
			series={{
				data: [43, 9, 92, 6, 35, 83, 16, 16],
				lineStyle: "solid",
			}}
		/>
		<br />
		<SparkLine
			series={{
				data: [43, 9, 92, 6, 35, 83, 16, 16],
				lineStyle: "dashed",
			}}
		/>
		<br />
		<SparkLine
			series={{
				data: [43, 9, 92, 6, 35, 83, 16, 16],
				lineStyle: "dotted",
			}}
		/>
	</>
);

const SizeExample = () => (
	<div style={{ display: "flex" }}>
		<SparkLine
			size="small"
			series={{
				data: [43, 9, 92, 6, 35, 83, 16, 16],
			}}
		/>
		<SparkLine
			size="large"
			series={{
				data: [43, 9, 92, 6, 35, 83, 16, 16],
			}}
		/>
	</div>
);

const PreAndPostFixDataFormatterExample = () => (
	<SparkLine
		style={{ width: "300px" }}
		series={{
			data: SERIE_DATA,
			dataPrefix: "+ ",
			dataPostfix: "%",
		}}
	/>
);

const CustomDataFormatterExample = () => (
	<SparkLine
		style={{ width: "300px" }}
		series={{
			data: SERIE_DATA,
			dataFormatter: (value) =>
				toFormattedNumberString({ number: value, locale: "en-US", format: "currency" }),
		}}
	/>
);

const RangesExample = () => (
	<>
		<div style={{ display: "flex" }}>
			<div style={{ flex: 0.5 }}>When the series are all 0</div>
			<div style={{ border: `1px dashed ${ColorGrayLighter}` }}>
				<SparkLine
					series={{
						data: [0, 0],
					}}
				/>
			</div>
		</div>
		<br />
		<div style={{ display: "flex" }}>
			<div style={{ flex: 0.5 }}>When the series are all 1</div>
			<div style={{ border: `1px dashed ${ColorGrayLighter}` }}>
				<SparkLine
					series={{
						data: [1, 1],
					}}
				/>
			</div>
		</div>
		<br />
		<div style={{ display: "flex" }}>
			<div style={{ flex: 0.5 }}>When the series goes from 0 to 1</div>
			<div style={{ border: `1px dashed ${ColorGrayLighter}` }}>
				<SparkLine
					series={{
						data: [0, 1],
					}}
				/>
			</div>
		</div>
		<br />
		<div style={{ display: "flex" }}>
			<div style={{ flex: 0.5 }}>When the series goes from 1 to 0</div>
			<div style={{ border: `1px dashed ${ColorGrayLighter}` }}>
				<SparkLine
					series={{
						data: [1, 0],
					}}
				/>
			</div>
		</div>
		<br />
		<div style={{ display: "flex" }}>
			<div style={{ flex: 0.5 }}>With custom minY</div>
			<div style={{ border: `1px dashed ${ColorGrayLighter}` }}>
				<SparkLine
					minY={-1}
					series={{
						data: [0, 1],
					}}
				/>
			</div>
		</div>
		<br />
		<div style={{ display: "flex" }}>
			<div style={{ flex: 0.5 }}>With custom maxY</div>
			<div style={{ border: `1px dashed ${ColorGrayLighter}` }}>
				<SparkLine
					maxY={2}
					series={{
						data: [0, 1],
					}}
				/>
			</div>
		</div>
	</>
);

const MultipleSeriesExample = () => (
	<SparkLine
		style={{ width: "300px", height: "64px" }}
		series={[
			{
				data: [0, 20, 0, 20, 20, 80, 60, 70, 100],
			},
			{
				data: [80, 50, 40, 50, 60, 65, 90, 50, 30],
			},
			{
				data: [10, 80, 40, 35, 10, 35, 10, 20, 50],
			},
		]}
	/>
);

const MultipleSeriesSizesExample = () => (
	<SparkLine
		style={{ width: "300px" }}
		series={[
			{
				data: [43, 9, 92, 6, 35, 83, 16, 16],
			},
			{
				data: [16, 83, 92, 35, 6],
			},
			{
				data: [35, 43],
			},
		]}
	/>
);

const numberOfItems = 200;
const tableData = generateTableData(numberOfItems);
/** @ignore */
const PerformanceExample = () => {
	const translations = {
		"aria-label": `List with ${numberOfItems} SparkLines`,
		noDataFoundLabel: "No data found",
		loadMoreLabel: (count: number) => `Load ${count} more`,
		loadAllLabel: "Load all",
	};
	const [isListVisible, setListVisibility] = useState<boolean>(false);

	const start = Date.now();
	useLayoutEffect(() => {
		document.getElementById("render-time")!.innerText =
			"Render time: " + (Date.now() - start) + "ms";
	});

	return (
		<>
			<div id="render-time"></div>
			<Button onClick={() => setListVisibility(!isListVisible)} variant="primary">
				{isListVisible ? "Hide list" : "Load a large list of SparkLines"}
			</Button>
			{isListVisible && (
				<ListTable
					columns={[
						{
							header: { content: "Description" },
							render: (dto) => dto.description,
							options: { isKeyColumn: true },
						},
						{
							header: { content: "Value" },
							render: (dto) => <SparkLine series={dto.series} />,
						},
					]}
					items={tableData}
					loading={false}
					loadMoreCount={100}
					style={{ marginTop: "10px" }}
					{...translations}
				/>
			)}
		</>
	);
};

const SERIE_DATA = [
	19, 77, 48, 39, 56, 24, 81, 38, 86, 63, 51, 12, 26, 80, 39, 94, 53, 8, 50, 45, 26, 27, 2, 48, 73,
	80, 86, 33, 33, 63, 74, 45, 66, 92, 22, 30, 71, 65, 16, 78, 22, 63, 29, 95, 11, 26, 41, 24, 55,
	90,
];

function generateRandomSerie({
	size = 30,
	minValue = 0,
	maxValue = 100,
}: {
	size?: number;
	minValue?: number;
	maxValue?: number;
}): SeriesOptions {
	const data: number[] = [];
	for (let i = 0; i < size; i++) {
		const value = Math.round(Math.random() * maxValue + minValue);
		data.push(value);
	}
	return { data };
}

function generateTableData(size = 10) {
	const items = [];
	for (let i = 0; i < size; i++) {
		items.push({
			description: `Row ${i + 1}`,
			series: [generateRandomSerie({}), generateRandomSerie({}), generateRandomSerie({})],
		});
	}
	return items;
}
